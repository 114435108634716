import { SortingType } from 'constants/index';

export enum TeamsSortFields {
  NAME = 'name',
  ROLE = 'role',
}

export const SORT_FIELDS = [
  {
    field: TeamsSortFields.NAME,
    labelId: 'work.orders.table.name',
  },
  {
    field: TeamsSortFields.ROLE,
    labelId: 'teams.table.role',
  },
];

export const DEFAULT_SORT = { field: TeamsSortFields.NAME, type: SortingType.DESC };
